<template>
  <div>
    <CCard class="c-card">
      <CToaster :autohide="3000">
        <template v-for="info in infoList">
          <CToast :key="info.message" :show="true" :header="info.header" :color="info.color">
            {{ info.message }}.
          </CToast>
        </template>
      </CToaster>
      <CCardBody>
        <div class="card-header-title">
          <CCardTitle>Change Password</CCardTitle>
          <CButton
            class="c-btn"
            color="light"
            @click="onResetPassword">
            Verify Request
          </CButton>
        </div>

        <CForm>

          <div class="form-group-login">
            <label for="password" class="form-label">
              Old Password
            </label>
            <input :type="showOldPassword ? 'text' : 'password'"
              class="form-control" v-model="oldPassword"/>
            <span @click="showOldPassword = !showOldPassword" class="eye-icon">
              <img :src="showOldPassword ? eyeClosedIcon : eyeOpenedIcon" alt="" class="column-icon">
            </span>
          </div>

          <div class="form-group-login">
            <label for="password" class="form-label">
              New Password
            </label>
            <input :type="showNewPassword ? 'text' : 'password'"
              class="form-control" v-model="newPassword"/>
            <span @click="showNewPassword = !showNewPassword" class="eye-icon">
              <img :src="showNewPassword ? eyeClosedIcon : eyeOpenedIcon" alt="" class="column-icon">
            </span>
          </div>

          <div class="form-group-login">
            <label for="password" class="form-label">
              Re-type Password
            </label>
            <input :type="showNewPasswordRetype ? 'text' : 'password'"
              class="form-control" v-model="newPasswordRetype"/>
            <span @click="showNewPasswordRetype = !showNewPasswordRetype" class="eye-icon">
              <img :src="showNewPasswordRetype ? eyeClosedIcon : eyeOpenedIcon" alt="" class="column-icon">
            </span>
          </div>
        </CForm>

        <!-- <a href="#" class="forgot-password">Forgot Password</a> -->
      </CCardBody>
    </CCard>
  </div>
</template>

<script>
let apiUrl = process.env.VUE_APP_API_URL;

export default {
  name: 'ResetPassword',
  data() {
    return {
      oldPassword: '',
      newPassword: '',
      newPasswordRetype: '',
      infoList: [],
      showOldPassword: false,
      showNewPassword: false,
      showNewPasswordRetype: false,

      eyeOpenedIcon: require('@/assets/images/icons/view.png'),
      eyeClosedIcon: require('@/assets/images/icons/hide.png')
    };
  },
  computed: {
    isFormValid() {
      return (
        this.oldPassword.trim() !== '' &&
        this.newPassword.trim() !== '' &&
        this.newPassword === this.newPasswordRetype
      );
    }
  },
  methods: {
    onResetPassword() {
      const isAnyFieldEmpty = !this.oldPassword || !this.newPassword || !this.newPasswordRetype;
      const passwordMatch = this.newPassword === this.newPasswordRetype;
      const passwordNotMatchMsg = "Passwords do not match";
      const passwordEmptyMsg = "Passwords cannot be empty";

      if (isAnyFieldEmpty) {
        this.toast("Error", passwordEmptyMsg, "danger");
        return;
      }

      if (!passwordMatch) {
        this.toast("Error", passwordNotMatchMsg, "danger");
        return;
      }

      const passwordData = {
        oldPassword: this.oldPassword,
        password: this.newPassword
      };

      this.updatePassword(passwordData);
    },
    updatePassword(passwordData) {
      auth.resetPassword(passwordData)
        .then(() => {
          this.toast("Info", "Password updated successfully", "success");
        })
        .catch(({ data }) => {
          const errorMessage = data?.message || "Unknown error occurred";
          this.toast("Error", errorMessage, "danger");
        });
    },

    toast(header, message, color) {
      var self = this;
      self.infoList.push({
        header: header,
        message: message,
        color: color,
      });
    },
  }
};
</script>

<style>
.c-card {
  max-width: 59rem;
  width: 100%;
  margin: 0 auto;
}

.card-header-title {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.forgot-password {
  color:  #8C8C8C;
  text-align: right;
  font-size: 16px;
  text-decoration: underline;
  margin-top: 40px;
  display: block;
}

button[disabled] {
  cursor: not-allowed;
}

.c-btn {
  padding: 8px 16px;
  font-size: 16px;
  line-height: 22px;
  border-radius: 50px;
  width: 176px;
}

.eye-icon {
  position: absolute;
  top: 50%;
  right: 10px;
  transform: translateY(-50%);
  cursor: pointer;
  font-size: 20px;
  color: #888;
  width: 20px;
  margin-top: 12px;
}

.eye-icon.active {
  color: #007bff; /* Change the color to indicate password visibility */
}

.form-group-login {
  position: relative;
  margin-bottom: 24px;
}
</style>
